import classes from "../userForm/addUserStyle.module.css";
import { Tooltip } from 'primereact/tooltip';
import * as Yup from "yup";
import Select from "react-select";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";

import { AutoComplete } from "primereact/autocomplete";
import axiosPrivate from "../../utils/http/axiosPrivate";
import LoaderContext from "../../context/Loader/LoaderContext";
import { showErrorToast, showSuccessToast } from "../../Toaster";
import moment from "moment";
import { Dialog } from "primereact/dialog";

// mobile validation check regex
const phoneRegExp =
  /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;

// validation
const validationRules = {
  name: Yup.string()
    .max(120, "Party name must not exceed 120 characters")
    .required("Party name is required"),

  mobile: Yup.string()
    .required("Mobile number is required")
    .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits"),

  college: Yup.string().max(120, "College name must not exceed 120 characters"),

  year: Yup.string().required("Year is required"),

  payment_mode: Yup.string().required("Payment mode is required"),
};

const EditCashMemo = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [indexItem, setIndexItem] = useState(0);
  const [discounType, setDiscountType] = useState("percentage");
  const [searchTerm, setSearchTerm] = useState("");
  const [itemDetails, setItemDetails] = useState([]);
  const [selectedIsbn, setSelectedIsbn] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [collegeResults, setCollegeResults] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [isDiscountInPercentage, setIsDiscountInPercentage] = useState(true);
  const [collegeName, setCollegeName] = useState("");
  const [selectedBook, setSelectedBook] = useState(null);
  const [visible, setVisible] = useState(false);
  const [selectedBookData, setSelectedBookData] = useState([]);

  const [totalItemAmountState, setTotalItemAmountState] = useState(0);
  const [filteredCollegeData, setfilteredCollegeData] = useState([]);

  useEffect(() => {
    const keyPressDetection = document.addEventListener("keypress", (e) => {
      if (e.key === "!" && selectedBook) {
        axiosPrivate
          .get(`/book-sold-reports/${selectedBook?.isbn}`)
          .then((res) => {
            setSelectedBookData(res.data.data);
            setVisible(true);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });

    return () => {
      document.removeEventListener("keydown", keyPressDetection);
    };
  }, [selectedBook]);

  const handleTitleClick = (index) => {
    // Retrieve the selected book data from formik values
    const selectedBookData = formik.values.items[index];
    setSelectedBook(selectedBookData);
  };
  const { setLoaderCheck, loaderCheck } = useContext(LoaderContext);

  const formik = useFormik({
    initialValues: {
      doc: "",
      user_id: "",
      title: "",
      date: "",
      course: "",
      mobile: "",
      college: "",
      year: "",
      freight: "",
      postage: "",
      advance: "",
      discount_type: false,
      round_off_discount: "",
      discount_percentage: "",
      discount_percentage_value: "",
      net_amount: "",
      payment_mode: "",
      remarks: "",
      items: new Array(1).fill({
        book_id: "",
        isbn: "",
        title: "",
        publisher_name: "",
        quantity: "",
        author: "",
        currency: {
          id: "",
          label: "",
          value: "",
          name: "",
        },
        rate: "",
        discount: 0,
        discount_value: 0,
        amount: "",
      }),
    },
    validationSchema: Yup.object(validationRules),

    onSubmit: (values, { setSubmitting, setFieldError }) => {
      // Filter out the empty items
      if (
        formik.values.items.length === 1 &&
        formik.values.items[0].isbn === "" &&
        formik.values.items[0].quantity === ""
      ) {
        alert("Please add items to the cash memo");
        return;
      }
      const filteredItems = values.items.filter(
        (item) => item.isbn !== "" || item.quantity !== ""
      );

      // Update the values.items with the filtered items
      values.items = filteredItems;

      values.items = values.items.map((item, index) => ({
        ...item,
      }));

      try {
        setLoaderCheck(true);
        axiosPrivate
          .put("cash-memo", values)
          .then((response) => {
            setLoaderCheck(false);
            if (response.data.code == 201) {
              showSuccessToast("Cash Memo Created Successfully");
              // Open the URL in a new tab
              // window.open(response?.data?.file_url, "_blank");
              navigate("/admin/cash-memo");
            }
          })
          .catch((error) => {
            if (typeof error?.response?.data?.errors == "array") {
              showErrorToast(error?.response?.data?.errors[0]?.msg);
            } else {
              showErrorToast(
                error.response.data.errors?.msg ??
                  error.response.errors?.message
              );
            }
          });
      } catch (error) {
        console.error(error);
        setLoaderCheck(false);
      }
    },
  });

  const [amountReceived, setAmountReceived] = useState(
    formik.values?.amount_received || 0
  );
  const [returnAmount, setReturnAmount] = useState(0);

  const handleAmountReceivedChange = (event) => {
    const received = parseFloat(event.target.value);
    setAmountReceived(received);
    // Calculate return amount
    const returned = received - formik.values.net_amount;
    setReturnAmount(returned);
  };

  //Call API to get last created cash memo no.
  useEffect(() => {
    setLoaderCheck(true);
    axiosPrivate
      .get(`cash-memo/${id}`)
      .then((response) => {
        setLoaderCheck(false);
        formik.setValues(response?.data?.data);

        response?.data?.data?.items?.forEach((item, index) => {
          formik.setFieldValue(`items[${index}].title`, item?.title);
          formik.setFieldValue(
            `items[${index}].publisher_name`,
            item?.publisher_name
          );
          formik.setFieldValue(
            `items[${index}].discount_percentage`,
            item?.discount
          );
          formik.setFieldValue(
            `items[${index}].discount_value`,
            item?.discount_value
          );

          formik.setFieldValue(`items[${index}].rate`, item?.rate);

          formik.setFieldValue(`items[${index}].author`, item?.author);

          formik.setFieldValue(`items[${index}].amount`, item?.amount);
          formik.setFieldValue(`items[${index}].quantity`, item?.quantity);

          formik.setFieldValue(`items[${index}].currency`, {
            name: item.currency_name,
            label: item.currency_label,
            value: item.currency_value,
            id: item.currency_id,
          });
        });

        console.log(response);
        //
      })
      .catch((error) => {
        setLoaderCheck(false);
        if (error.response?.status === 404) {
          formik.setFieldValue("doc", "APDPL/24-25/1");
        }
      });
  }, []);

  // Call the fetchResults function when the search term changes
  useEffect(() => {
    const delay = setTimeout(() => {
      if (searchTerm) {
        fetchResults();
      }
    }, 1000); // Adjust the delay time as needed (in milliseconds)

    return () => clearTimeout(delay);
  }, [searchTerm]);

  useEffect(() => {
    axiosPrivate
      .get("currency-at-cash-memo")
      .then((res) => {
        setCurrencyOptions(() => {
          const options = [];

          res?.data?.data?.forEach((currency) => {
            options.push({
              id: currency.id,
              name: currency.name,
              label: currency.symbol,
              value: currency.value,
            });
          });

          return options;
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // function handle amount of individual item
  useEffect(() => {
    const timer = setTimeout(() => {
      const rate = formik.values.items[indexItem]?.rate;
      const quantity = formik.values.items[indexItem]?.quantity;
      const discount = formik.values.items[indexItem]?.discount;
      const discountValue = formik.values.items[indexItem]?.discount_value;
      const currency_value = formik.values.items[indexItem]?.currency?.value;

      let totalItemAmount = 0;
      formik.values.items.forEach((item, index) => {
        if (indexItem != index) totalItemAmount += Number(item.amount);
      });

      if (rate && quantity && currency_value && discounType) {
        if (discounType === "percentage") {
          const discountValue =
            (rate * quantity * currency_value * discount) / 100;
          const amount = rate * quantity * currency_value - discountValue;

          formik.setFieldValue(
            `items[${indexItem}].discount_value`,
            discountValue
          );
          formik.setFieldValue(`items[${indexItem}].amount`, amount);

          totalItemAmount += Number(amount);
        } else {
          const newDiscount =
            (discountValue / (rate * quantity * currency_value)) * 100;
          const amount = rate * quantity * currency_value - discountValue;

          formik.setFieldValue(
            `items[${indexItem}].discount`,
            newDiscount.toFixed(2)
          );
          formik.setFieldValue(`items[${indexItem}].amount`, amount);

          totalItemAmount += Number(amount);
        }
      } else if (rate && quantity && currency_value) {
        const amount = rate * quantity * currency_value;
        formik.setFieldValue(`items[${indexItem}].amount`, amount);
        totalItemAmount += Number(amount);
      } else {
        formik.setFieldValue(`items[${indexItem}].amount`, 0);
        formik.setFieldValue(`items[${indexItem}].discount`, 0);
        formik.setFieldValue(`items[${indexItem}].discount_value`, 0);
      }

      const postage = formik.values.postage;
      const freight = formik.values.freight;
      const advance = formik.values.advance;
      const total_discount = formik.values.round_off_discount;

      const totalAmount =
        Number(totalItemAmount) +
        Number(postage) +
        Number(freight) -
        Number(advance);

      if (isDiscountInPercentage) {
        const discountValue = Number((totalAmount * total_discount) / 100);
        const netAmount = Number(totalAmount - discountValue);

        formik.setFieldValue("discount_percentage", total_discount);
        formik.setFieldValue("discount_percentage_value", discountValue);
        formik.setFieldValue("net_amount", netAmount);
      } else {
        const netAmount = Number(totalAmount - total_discount);
        const discount_percentage = Number(
          (total_discount / totalAmount) * 100
        ).toFixed(2);

        formik.setFieldValue("discount_percentage_value", total_discount);
        formik.setFieldValue("discount_percentage", discount_percentage);
        formik.setFieldValue("net_amount", netAmount);
      }

      setTotalItemAmountState(totalItemAmount);
    }, 500);

    return () => clearTimeout(timer);
  }, [
    formik.values.items[indexItem]?.rate,
    formik.values.items[indexItem]?.currency,
    formik.values.items[indexItem]?.quantity,
    formik.values.items[indexItem]?.discount,
    formik.values.items[indexItem]?.discount_value,
    formik.values.freight,
    formik.values.postage,
    formik.values.advance,
    formik.values.discount_type,
    formik.values.round_off_discount,
    formik.values.items,
  ]);

  // function to check if discount value is valid
  const isValidDiscountValue = (value) => {
    const rate = formik.values.items[indexItem]?.rate;
    const quantity = formik.values.items[indexItem]?.quantity;
    const currency_value = formik.values.items[indexItem]?.currency.value;

    if (rate * quantity * currency_value < value) {
      showErrorToast("Discount value should be less than total amount");
      return false;
    }
    return true;
  };

  const isValidDiscountPercentage = () => {
    const discountPercentage = formik.values.round_off_discount;
    if (discountPercentage > 100) {
      showErrorToast("Discount percentage should be less than 100");
      return false;
    }
    return true;
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target?.value);
  };

  //Function to add row into table grid
  const addItem = () => {
    formik.values.items.push({
      isbn: "",
      title: "",
      publisher_name: "",
      quantity: "",
      author: "",
      currency: {
        id: "",
        label: "",
        value: "",
      },
      rate: "",
      discount: 0,
      discount_value: 0,
      amount: "",
    });

    formik.setValues({ ...formik.values });

    setItemDetails([
      ...itemDetails,
      {
        isbn: "",
        title: "",
        publisher_name: "",
        quantity: "",
        currency: {
          id: "",
          label: "",
          value: "",
        },
        rate: "",
        discount: 0,
        discount_value: 0,
        amount: "",
      },
    ]);
  };

  //Function to remove row from table grid
  const removeItem = (index) => {
    if (index >= 0 && formik.values.items.length > 1) {
      // Remove item from formik values
      const updatedItems = [...formik.values.items];
      updatedItems.splice(index, 1);
      formik.setValues({ ...formik.values, items: updatedItems });

      // Remove item from item details
      setItemDetails((prevState) => {
        const updatedDetails = [...prevState];
        updatedDetails.splice(index, 1);
        return updatedDetails;
      });
    }
  };

  // API call to get data based on search keyword
  const fetchResults = async () => {
    try {
      const response = await axiosPrivate.get(`book-search/${searchTerm}`);
      const data = await response?.data?.data;
      setSearchResults(data);
    } catch (err) {
      if (err.response.status === 404) {
        showErrorToast(
          err?.response?.data?.message ||
            err?.response?.data?.errors[0]?.msg ||
            err?.response?.data?.errors?.msg
        );
      }
    }
  };

  //Function to format serach suggestion
  const searchSuggestions = (event) => {
    const query = event.query;
    if (
      Array.isArray(searchResults) &&
      query?.length > 0 &&
      searchResults.length > 0
    ) {
      const formattedSuggestions = searchResults
        .filter((result) =>
          result.isbn?.toLowerCase().includes(query?.toLowerCase())
        )
        .map((result) => ({
          title: result.title,
          resultType: result.result_type,
          isbn: result.isbn,
          author: result.author,
          college: result.college,
          bookCategoryName: result.book_category_name,
          subCatName: result.sub_cat_name,
          publisherName: result.publisher_name,
          currencySymbol: result.currency,
          rate: result.mrp_rate,
          discount: result.discount,
          discountValue: result.discount_value,
        }));
      return formattedSuggestions;
    }
    return [];
  };

  const updateCollegeResults = (e) => {
    console.log(e);
    const formattedSuggestions = collegeResults
      .filter((result) =>
        result.college_name.toLowerCase().includes(e.query?.toLowerCase())
      )
      .map((result) => ({
        college_name: result.college_name,
      }));
    setfilteredCollegeData(formattedSuggestions);
  };

  //Function to handle isbn change
  const handleIsbnChange = (index, isbn) => {
    if (isbn != "") {
      let itemIndex = formik.values.items.findIndex(
        (item) => item?.isbn === isbn
      );
      if (itemIndex !== -1) {
        formik.setFieldValue(
          `items[${itemIndex}].quantity`,
          formik.values.items[itemIndex].quantity + 1
        );
      } else {
        axiosPrivate
          .get(`books/get/${isbn}`)
          .then((book) => {
            const updatedDetails = [...itemDetails];
            updatedDetails[index] = {
              title: book?.data?.data?.title || "",
              publisher_name: book?.data?.data?.publisher_name || "",
              rate: book?.data?.data?.mrp_rate || "",
              discount: book?.data?.data?.discount || 0,
              discount_value: book?.data?.data?.discount_value || 0,
              author: book?.data?.data?.author || "",
              currency: {
                id: book?.data?.data?.currency_id || "",
                label: book?.data?.data?.currency_symbol || "",
                value: book?.data?.data?.currency_value || "",
                name: book?.data?.data?.currency_name || "",
              },
            };

            setItemDetails(updatedDetails);

            let amountSum =
              updatedDetails[index]?.rate -
              updatedDetails[index]?.rate *
                (updatedDetails[index]?.discount / 100);

            formik.setFieldValue(
              `items[${index}].title`,
              updatedDetails[index].title
            );
            formik.setFieldValue(
              `items[${index}].publisher_name`,
              updatedDetails[index].publisher_name
            );
            formik.setFieldValue(
              `items[${index}].discount`,
              updatedDetails[index]?.discount || 0
            );
            formik.setFieldValue(
              `items[${index}].discount_value`,
              updatedDetails[index]?.discount_value || 0
            );

            formik.setFieldValue(
              `items[${index}].rate`,
              updatedDetails[index]?.rate
            );
            formik.setFieldValue(
              `items[${index}].currency`,
              updatedDetails[index]?.currency
            );
            formik.setFieldValue(
              `items[${index}].author`,
              updatedDetails[index]?.author
            );
            formik.setFieldValue(`items[${index}].amount`, amountSum);
            formik.setFieldValue(`items[${index}].quantity`, 1);

            setSelectedIsbn("");
          })
          .catch((error) => {
            showErrorToast(error?.response?.data?.errors?.msg);
            formik.setFieldValue(`items[${index}].quantity`, null);
            formik.setFieldValue(`items[${index + 1}].quantity`, null);
          });
      }
    }
  };

  const fetchDataThroughMobile = (mobile) => {
    axiosPrivate
      .get(`users-get-by-number/${mobile}`)
      .then((res) => {
        formik.setFieldValue("user_id", res.data.data.id);
        formik.setFieldValue("college", res.data.data.college_name);
        formik.setFieldValue("year", res.data.data.year);
        formik.setFieldValue("name", res.data.data.name);
      })
      .catch((error) => {
        showErrorToast(error?.response?.data?.errors?.msg);
      });
  };

  //Set isbn of selected item from search results
  const handleIsbnChangeForSelectedTitle = (e) => {
    if (e.value) {
      const selectedItem = e.value;
      // Check if the ISBN is already in the items array
      const existingIndex = formik.values.items.findIndex(
        (item) => item.isbn === selectedItem.isbn
      );

      if (existingIndex !== -1) {
        setIndexItem(existingIndex);
        // If the ISBN already exists, increment the quantity by 1
        const updatedQuantity =
          Number(formik.values.items[existingIndex].quantity) + 1;
        formik.setFieldValue(
          `items[${existingIndex}].quantity`,
          updatedQuantity
        );
      } else {
        // Find the next available empty index in the items array
        formik.values.items.push({
          isbn: "",
          title: "",
          publisher_name: "",
          quantity: "",
          currency: {
            id: "",
            label: "",
            value: "",
            name: "",
          },
          author: "",
          rate: 0,
          discount: 0,
          discount_value: 0,
          amount: 0,
        });

        const emptyIndex = formik.values.items.findIndex((item) => !item.isbn);

        if (emptyIndex !== -1) {
          formik.setFieldValue(`items[${emptyIndex}].isbn`, selectedItem.isbn);
          handleIsbnChange(emptyIndex, selectedItem.isbn);
        }
      }
    }
  };

  return (
    <>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <form onSubmit={(e) => formik.handleSubmit(e)}>
              <div
                className="tab-pane fade show active"
                id="cash-memo-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-header d-flex">
                    <strong
                      className="cash-memo-head"
                      style={{ fontWeight: "700", fontSize: "18px" }}
                    >
                      Cash Memo Details :
                    </strong>
                  </div>

                  <div className="card-body pt-0">
                    <div className="row">
                      <div className="col">
                        <div className="form-group align-items-center">
                          <label
                            style={{ fontSize: "15px" }}
                          >
                            Mobile:<span className="required">*</span>
                          </label>
                          <input
                            id="mobile"
                            name="mobile"
                            type="number"
                            className="form-control"
                            placeholder="Mobile *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.mobile}
                            onInput={(e) => {
                              if (e.target.value.length > 10) {
                                e.target.value = e.target.value.slice(0, 10);
                              }
                              if (e.target.value.length === 10) {
                                fetchDataThroughMobile(e.target.value);
                              }
                            }}
                            readOnly
                          />
                        </div>
                        <div
                          style={{
                            position: "relative",
                            bottom: 15,
                            left: 7,
                          }}
                        >
                          {formik.touched.mobile && formik.errors.mobile ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.mobile}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* Name */}
                      <div className="col">
                        <div className="form-group align-items-center">
                          <label
                            style={{ fontSize: "15px" }}
                          >
                            Party name:<span className="required">*</span>
                          </label>
                          <input
                            id="name"
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Party name *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.name}
                            readOnly
                          />
                        </div>
                        <div
                          style={{
                            position: "relative",
                            bottom: 15,
                            left: 7,
                          }}
                        >
                          {formik.touched.name && formik.errors.name ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors?.name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* Date */}
                      <div className="col">
                      <div className="form-group align-items-center">
                        <label
                          style={{ fontSize: "15px" }}
                        >
                          Date :<span className="required">*</span>
                        </label>
                        <input
                          id="date"
                          name="date"
                          type="text"
                          className="form-control"
                          placeholder="Enter date *"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={moment(formik.values?.date).format("DD/MM/YYYY")}
                          readOnly
                          onDrop={(e) => e.preventDefault()}

                        />
                      </div>
                      </div>

                      <div className="col">
                        <div className="form-group align-items-center">
                          <label style={{ fontSize: "15px" }}>
                            Payment:
                          </label>
                          <Select
                            className="w-100"
                            options={[
                              { label: "Cash", value: "Cash" },
                              {
                                label: "UPI",
                                value: "Cash Sale (Paytm/Gpay/Google)",
                              },
                              { label: "Card", value: "CARD" },
                              { label: "Cheque", value: "CARD" },
                              { label: "NEFT/RTGS", value: "CARD" },
                            ]}
                            onChange={(e) => {
                              formik.setFieldValue("payment_mode", e.value);
                            }}
                            value={{
                              label: formik.values.payment_mode,
                              value: formik.values.payment_mode
                            }}
                            isDisabled
                          />
                        </div>
                        <div
                          style={{
                            display: "relative",
                            bottom: 0,
                            left: 7,
                          }}
                        >
                          {formik.touched.payment_mode &&
                          formik.errors.payment_mode ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.payment_mode}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    {/* Doc No */}
                    <div className="row">
                    <div className="col">
                      <div className="form-group align-items-center">
                        <label
                          style={{ fontSize: "15px" }}
                        >
                          Doc No:<span className="required">*</span>
                        </label>
                        <input
                          id="doc"
                          name="doc"
                          type="text"
                          className="form-control"
                          placeholder="Doc No *"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values?.doc}
                          readOnly
                        />
                        {formik.touched.doc && formik.errors.doc ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.doc}
                          </div>
                        ) : null}
                       </div>
                      </div>
                       {/* College */}
                       <div className="col">
                        <div className="form-group align-items-center">
                          <label
                            style={{ fontSize: "15px" }}
                          >
                            College:
                          </label>
                          <Tooltip target=".select-tooltip" position="top" />
                          <Select
                            name="college"
                            id="college"
                            value={{
                              label: formik.values?.college,
                              value: formik.values?.college,
                            }}
                            placeholder="College"
                            className="w-100"
                            isDisabled
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group align-items-center">
                          <label
                            style={{ fontSize: "15px" }}
                          >
                            Course:
                          </label>
                          <Select
                            id="course"
                            name="course"
                            className="w-100"
                            value={{
                              label: formik.values?.course,
                              value: formik.values?.course,
                            }}
                            placeholder="Course"
                            onChange={formik.handleChange}
                            defaultValue={formik.values?.course}
                            isDisabled
                            Tooltip="Course"
                            TooltipOptions={{
                              position: "top",
                            }}
                          />
                        </div>
                      </div>
                      {/* Year */}
                      <div className="col">
                        <div className="form-group align-items-center">
                          <label
                            style={{ fontSize: "15px" }}
                          >
                            Year:
                          </label>
                          <Select
                            id="year"
                            name="year"
                            className="w-100"
                            value={{
                              label: formik.values?.year,
                              value: formik.values?.year,
                            }}
                            // value={formik.values?.year}
                            // options={
                            //   formik.values?.year
                            // }
                            placeholder="Year"
                            onChange={formik.handleChange}
                            defaultValue={formik.values?.year}
                            isDisabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="d-flex mt-3 col-md-12 form-group">
                        <div className="w-50 d-flex align-items-center">
                          <label
                            style={{ fontSize: "17px" }}
                          >
                            Search:
                          </label>
                          <AutoComplete
                            value={searchTerm}
                            suggestions={searchResults}
                            field="title"
                            completeMethod={searchSuggestions} // This is where you bind the function
                            onChange={(e) => handleInputChange(e)}
                            onFocusCapture={
                              (e) => setSearchResults([]) // Clear the search results when the input is focused
                            }
                            itemTemplate={(item) => (
                                <div className="form-control">
                                  {item.isbn} / {item.author} / {item.title} /{" "}
                                  {item.publisher_name} / {item.currency} /{" "}
                                  {item.mrp_rate}
                              </div>
                            )}
                            placeholder="Search..."
                            className="col d-flex"
                            onSelect={(e) => {
                              handleIsbnChangeForSelectedTitle(e);
                              setSearchTerm("");
                            }}
                            inputClassName="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <label className="col-auto font-weight-bold cash-memo-head">
                      ITEMS:
                    </label>
                    <div
                      className="table-responsive border"
                      style={{ height: "fit-content", overflowY: "scroll" }}
                    >
                      <table className="table table-bordered mb-0">
                        <thead>
                          <tr className="border-bottom">
                            <th className="font-weight-bold">Sr.</th>
                            <th className="pr-3 col-md-1 font-weight-bold">ISBN </th>
                            <th className="col-md-5 font-weight-bold">Title</th>
                            {/* <th className="font-weight-bold">Publisher</th> */}
                            <th className="font-weight-bold">Curr.</th>
                            <th className="font-weight-bold">QTY.</th>
                            <th className="font-weight-bold">Rate</th>
                            <th className="font-weight-bold">Disc(%)</th>
                            <th className="font-weight-bold">Disc.</th>
                            <th className="font-weight-bold">Amount</th>
                            <th className="font-weight-bold">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formik.values.items.map((item, index) => (
                            <tr
                              key={index}
                              onClick={() => {
                                handleTitleClick(index);
                              }}
                            >
                              {/* Index No */}
                              <td
                                className="p-0"
                                style={{ textAlign: "center" }}
                              >
                                {index + 1}
                              </td>
                              {/* ISBN */}
                              <td className="p-0">
                                <input
                                  id={`items[${index}].isbn`}
                                  name={`items[${index}].isbn`}
                                  type="text"
                                  className="form-control col"
                                  placeholder="Enter ISBN"
                                  onChange={(e) => {
                                    if (e.target.value.includes("!")) return;
                                    formik.handleChange(e);
                                    setSelectedIsbn(e.target.value);
                                    handleIsbnChange(index, e.target.value);
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={
                                    formik?.values?.items[index]?.isbn || ""
                                  }
                                />
                              </td>
                              {/* Title */}
                              <td className="p-0">
                                <input
                                  id={`items[${index}].title`}
                                  name={`items[${index}].title`}
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Title"
                                  onChange={(e) => {
                                    if (e.target.value.includes("!")) return;
                                    formik.handleChange();
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={
                                    formik.values?.items[index]?.title || ""
                                  }
                                />
                              </td>
                              {/* Publisher */}
                              {/* <td className="p-0">
                                <input
                                  id={`items[${index}].publisher_name`}
                                  name={`items[${index}].publisher_name`}
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Publisher"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={
                                    formik.values.items[index]
                                      ?.publisher_name || ""
                                  }
                                />
                              </td> */}
                              {/* Currency */}
                              <td className="p-0">
                                <Select
                                  menuPlacement="auto"
                                  defaultValue={
                                    formik.values.items[index]?.currency
                                  }
                                  value={formik.values?.items[index]?.currency}
                                  onChange={(e) => {
                                    setIndexItem(index);
                                    formik.setFieldValue(
                                      `items[${index}].currency`,
                                      {
                                        name: e.name,
                                        label: e.label,
                                        value: e.value,
                                        id: e.id,
                                      }
                                    );
                                  }}
                                  options={currencyOptions}
                                  styles={{
                                    control: (base) => ({
                                      ...base,
                                      overflow: "auto",
                                    }),
                                    menu: (provided) => ({
                                      ...provided,
                                      zIndex: 9999,
                                    }),
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  menuPortalTarget={document.body}
                                />
                              </td>
                              {/* Quantity */}
                              <td className="p-0">
                                <input
                                  id={`items[${index}].quantity`}
                                  name={`items[${index}].quantity`}
                                  type="number"
                                  className="number form-control"
                                  placeholder="Enter Quantity"
                                  onChange={(e) => {
                                    setIndexItem(index);
                                    formik.setFieldValue(
                                      `items[${index}].quantity`,
                                      e.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={formik.values?.items[index]?.quantity}
                                />
                              </td>
                              {/* Rate */}
                              <td className="p-0">
                                <input
                                  id={`items[${index}].rate`}
                                  name={`items[${index}].rate`}
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter Rate"
                                  onChange={(e) => {
                                    setIndexItem(index);
                                    formik.setFieldValue(
                                      `items[${index}].rate`,
                                      e.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={formik.values?.items[index]?.rate}
                                />
                              </td>
                              {/* Discount */}
                              <td className="p-0">
                                <input
                                  id={`items[${index}].discount`}
                                  name={`items[${index}].discount`}
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter Discount"
                                  onChange={(e) => {
                                    setIndexItem(index);
                                    setDiscountType("percentage");

                                    if (e.target.value > 100) return;
                                    formik.setFieldValue(
                                      `items[${index}].discount`,
                                      e.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={formik.values?.items[index]?.discount.toFixed(2)}
                                />
                              </td>
                              <td className="p-0">
                                <input
                                  id={`items[${index}].discount_value`}
                                  name={`items[${index}].discount_value`}
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter Discount Value"
                                  onChange={(e) => {
                                    setIndexItem(index);
                                    setDiscountType("value");
                                    if (!isValidDiscountValue(e.target.value))
                                      return;
                                    formik.setFieldValue(
                                      `items[${index}].discount_value`,
                                      e.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={
                                    formik.values?.items[index]?.discount_value.toFixed(2)
                                  }
                                />
                              </td>
                              {/* Amount */}
                              <td className="p-0">
                                <input
                                  id={`items[${index}].amount`}
                                  name={`items[${index}].amount`}
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter Amount"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={
                                    formik.values?.items[index]?.amount < 0
                                      ? 0
                                      : formik.values.items[index]?.amount
                                  }
                                  readOnly
                                />
                              </td>
                              {/* Action */}
                              <td className="p-0 d-flex justify-content-center">
                                {index >= 0 && (
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => removeItem(index)}
                                  >
                                    <FaMinus />
                                  </button>
                                )}
                                {index + 1 === formik.values.items.length && (
                                  <button
                                    type="button"
                                    className="btn btn-primary ml-2"
                                    onClick={addItem}
                                  >
                                    <FaPlus />
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <th colSpan={7} className="m-0 p-0">
                              <th className="col-auto">
                                Author: {selectedBook?.author}
                              </th>
                              <th className="col-auto">
                                Pub: {selectedBook?.publisher_name}
                              </th>
                              <th className="col-auto">Stock:{selectedBook?.units} </th>
                            </th>
                            <td className="p-2" colSpan={1}>
                              Total Amount
                            </td>
                            <td className="pl-3 p-2 label bg-light" colSpan={1}>
                              {totalItemAmountState.toFixed(2)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    {/* <div className="table-responsive mt-3 bordered ">
                      <table className="table-bordered font-weight-light">
                        <thead>
                          <tr>
                            <th className="col-auto font-weight-light">Author: {selectedBook?.author}</th>
                            <th className="col-auto font-weight-light">Pub: {selectedBook?.publisher_name}</th>
                            <th className="col-auto font-weight-light">Stock: </th>
                          </tr>
                        </thead>
                      </table>
                    </div> */}

                    <div className="row col-md-auto mb-2 justify-content-end">
                      <div className="row col-md-auto bg-light p-1 justify-content-center"></div>
                    </div>
                    <div className="d-flex flex-row justify-content-between">
                      {/* Left area  */}
                      <div className="col-md-5 col-sm-5 form-group">
                        <div className="col-12 font-weight-bold cash-memo-head">
                          Remarks :
                        </div>
                        <div className="mt-2">
                          <textarea
                            id="remarks"
                            name="remarks"
                            type="text"
                            placeholder="Enter Remarks"
                            style={{ height: "110px" }}
                            className="form-control"
                            value={formik.values?.remarks}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>

                      {/* Right area */}
                      <div className="row justify-content-end mt-2">
                        <div className="p-2  d-flex flex-row justify-content-end">
                          <div className="d-flex flex-column justify-content-center mr-2 col-md-5">
                            <div className=" form-group d-flex align-items-center">
                              <label
                                className="col"
                                style={{ fontSize: "15px" }}
                              >
                                Freight:
                              </label>
                              <input
                                id="freight"
                                name="freight"
                                type="number"
                                className="form-control col"
                                placeholder="Enter Freight *"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values?.freight}
                              />
                            </div>
                            <div className="form-group d-flex align-items-center">
                              <label
                                className="col"
                                style={{ fontSize: "15px" }}
                              >
                                Postage:
                              </label>
                              <input
                                id="postage"
                                name="postage"
                                type="number"
                                className="form-control col"
                                placeholder="Enter postage *"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values?.postage}
                              />
                            </div>

                            {/* Advance */}
                            <div className="form-group d-flex align-items-center">
                              <label
                                className="col"
                                style={{ fontSize: "15px" }}
                              >
                                Advance:
                              </label>
                              <input
                                id="advance"
                                name="advance"
                                type="number"
                                className="form-control col"
                                placeholder="Enter advance *"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values?.advance}
                              />
                            </div>
                          </div>
                          <div className="d-flex flex-column justify-content-center col-md-5">
                            <div className="form-group d-flex align-items-center">
                              <div className="row">
                                <div className="col-auto d-flex align-items-center">
                                  <label
                                    className="col-auto"
                                    style={{ fontSize: "15px" }}
                                  >
                                    Discount :
                                  </label>
                                  <input
                                    id="discount_type"
                                    name="discount_type"
                                    type="checkbox"
                                    onBlur={formik.handleBlur}
                                    value={formik.values?.discount_type}
                                    onChange={(e) => {
                                      formik.handleChange(e);
                                      if (!isValidDiscountPercentage()) return;
                                      setIsDiscountInPercentage(
                                        !isDiscountInPercentage
                                      );
                                    }}
                                    checked={isDiscountInPercentage}
                                  />{" "}
                                  %
                                </div>
                                <div className="d-flex col">
                                  <input
                                    id="round_off_discount"
                                    name="round_off_discount"
                                    type="number"
                                    className="form-control mr-2"
                                    placeholder="Enter discount *"
                                    onChange={(e) => {
                                      if (
                                        isDiscountInPercentage &&
                                        e.target.value > 100
                                      )
                                        return;
                                      formik.handleChange(e);
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values?.round_off_discount}
                                  />
                                  <input
                                    type="text"
                                    id="discount_percentage"
                                    name="discount_percentage"
                                    className="form-control"
                                    placeholder="Enter discount percentage *"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={
                                      formik.values?.discount_percentage_value
                                    }
                                    readOnly
                                  />
                                </div>
                              </div>
                            </div>
                            {/* Round Off */}
                            <div className="form-group d-flex align-items-center">
                              <label
                                className="col-auto"
                                style={{ fontSize: "15px", width: "130px" }}
                              >
                                Round Off :
                              </label>
                              <input
                                id="rount_off"
                                name="rount_off"
                                type="number"
                                className="form-control col"
                                placeholder="Enter rount off *"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={0.0}
                                disabled={true}
                              />
                            </div>
                            {/* Net Amount */}
                            <div className="form-group d-flex align-items-center">
                              <label
                                className="col-auto"
                                style={{ fontSize: "15px", width: "130px" }}
                              >
                                Net Amount :
                              </label>
                              <input
                                id="net_amount"
                                name="net_amount"
                                type="number"
                                className="form-control col"
                                placeholder="Enter net amount *"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values?.net_amount}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                        <div className="mr-2 row d-flex justify-content-end">
                          <div className="form-group d-flex align-items-center mt-3 mr-2">
                            <label className="col" style={{ fontSize: "15px" }}>
                              Amount Received :{" "}
                              <input
                                id="amount_received"
                                name="amount_received"
                                type="number"
                                className="col form-control mt-1"
                                placeholder="Enter Amount Received *"
                                onChange={handleAmountReceivedChange}
                                value={amountReceived}
                              />
                            </label>
                          </div>

                          <div className="row justify-content-center align-items-center mt-3 p-3 mr-2">
                            {" "}
                            -{" "}
                          </div>

                          <div className="form-group d-flex align-items-center mt-3 mr-2">
                            <label className="col" style={{ fontSize: "15px" }}>
                              Net Amount
                              <input
                                id="net_amount"
                                name="net_amount"
                                type="number"
                                className="col form-control mt-1"
                                placeholder="Net Amount *"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values?.net_amount}
                                readOnly
                              />
                            </label>
                          </div>

                          <div className="row justify-content-center align-items-center mt-3 p-3 mr-2">
                            {" "}
                            ={" "}
                          </div>

                          <div className="form-group d-flex align-items-center mt-3">
                            <label className="col" style={{ fontSize: "15px" }}>
                              Return
                              <input
                                id="return_amount"
                                name="return_amount"
                                type="number"
                                className="col form-control mt-1"
                                placeholder="Return Amount"
                                value={returnAmount.toFixed(2)}
                                readOnly
                              />
                            </label>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12 submit-btn">
                      <div className="form-group close-submit">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={() => navigate("/admin/cash-memo")}
                        >
                          Close
                        </button>
                        <button type="submit" className="btn btn-primary ml-2">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Dialog
        header="Sold Book Details"
        // headerStyle={{ paddingLeft: "6%"}}
        visible={visible}
        style={{ margin: "auto", width: "80%", height: "80%" }}
        onHide={() => setVisible(false)}
      >
          <div className="table-responsive">
            <table className="table table-striped table-bordered border-top">
              <thead>
                <tr>
                  <th>Doc No</th>
                  <th>ISBN</th>
                  <th>Title</th>
                  <th>MRP Rate</th>
                  <th>Quantity</th>
                  <th>Currency</th>
                  <th>Discount Value</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {selectedBookData?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.doc}</td>
                    <td>{item.isbn}</td>
                    <td>{item.title}</td>
                    <td>{item.rate}</td>
                    <td>{item.quantity}</td>
                    <td>{item.currency}</td>
                    <td>{item.discount_value}</td>
                    <td>{item.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
      </Dialog>
    </>
  );
};

export default EditCashMemo;
