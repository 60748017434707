import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { showErrorToast, showSuccessToast } from "../../Toaster";
import axiosPrivate from "../../utils/http/axiosPrivate";
import AuthContext from "../../context/auth/AuthContext";
import DataTable2 from "../../context/components/DataTable2";
import { FaGreaterThan, } from "react-icons/fa";
import { GrDownload, GrUpload, GrView } from "react-icons/gr";
import { AiOutlineEdit } from "react-icons/ai"; // Importing edit and PDF icons
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import moment from "moment/moment";
import { CgTally } from "react-icons/cg";
import html2pdf from "html2pdf.js";


const CashMemoList = ({ authPermission }) => {
  const { authPermissions } = useContext(AuthContext);
  const [cashMemoState, setCashMemoState] = useState([]);
  const [selectedCashMemo, setSelectedCashMemo] = useState([]);
  const [visible, setVisible] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => setVisible(true)}
        className="p-button-text"
      />
    </div>
  );

  const navigate = useNavigate();

  const headers = [
    {
      name: "Payment Mode",
      field: "payment_mode",
      sortable: true,
      classKey: "",
    },
    { name: "Doc No.", field: "doc", sortable: true, classKey: "" },
    { name: "Name", field: "name", sortable: true, classKey: "" },
    { name: "Date", field: "date", sortable: true, classKey: "" },
    { name: "Net Amount", field: "net_amount", sortable: true, classKey: "" },
    { name: "ACTION", field: "action", classKey: "" },
  ];

  const item_headers = [
    { name: "Title", field: "title", sortable: true, classKey: "" },
    { name: "ISBN", field: "isbn", sortable: true, classKey: "" },
    { name: "Quantity", field: "quantity", sortable: true, classKey: "" },
    { name: "Currency", field: "currency_label", sortable: true, classKey: "" },
    { name: "Rate", field: "rate", sortable: true, classKey: "" },
    { name: "Discount", field: "discount_value", sortable: true, classKey: "" },
    { name: "Amount", field: "amount", sortable: true, classKey: "" },
  ];

  const searchItems = ["name", "payment_mode"];
  const ItemssearchItems = ["isbn"];

  const getCashMemo = () => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    axiosPrivate
      .get("cash-memo", options)
      .then((cash_memo) => {
        // showSuccessToast("Cash Memo List Fetched Successfully");
        const cashMemoData = cash_memo?.data?.data?.map((value, key) => {
          const formattedDate = moment(value.date).format("DD-MM-YYYY");
          let buttons = [];
          buttons.push(
            <button
              key="viewButton##1"
              type="button"
              data-id={value.id}
              // onClick={() => {
              //   setVisible(true);
              //   console.log(value);
              //   setPdfUrl(`${process.env.REACT_APP_BACKEND_MAIN_URL}/invoice/${value.invoice_pdf}`);
              // }}
              onClick={() => viewCashMemoHandler(value.id)}
              className="btn btn-icon js-sweetalert"
              title="View"
            >
              <GrView style={{ color: "red" }} size={17} />
            </button>
          );
          buttons.push(
            // Adding edit button
            <button
              key="editButton##2"
              type="button"
              data-id={value.id}
              onClick={() => editCashMemoHandler(value.id)}
              className="btn btn-icon"
              title="Edit"
            >
              <AiOutlineEdit style={{ color: "blue" }} size={17} />
            </button>
          );
          if (value.tally_invoice_check === 0) {
            buttons.push(
              // Adding print PDF button
              <button
                key="printPdfButton##3"
                type="button"
                data-id={value.id}
                onClick={() => printPdfHandler(value.id)}
                className="btn btn-icon"
                title="Generate Invoice"
              >
                <GrUpload style={{ color: "green" }} size={17} />
              </button>
            );
          } else if (value.tally_invoice_check === -1) {
            buttons.push(
              // Adding print PDF button
              <button
                key="printPdfButton##3"
                type="button"
                data-id={value.id}
                onClick={() => updatePdfHandler(value.id)}
                className="btn btn-icon"
                title="Generate Invoice"
              >
                <GrUpload style={{ color: "green" }} size={17} />
              </button>
            );
          } else {
            buttons.push(
              // Adding edit button
              <button
                key="viewPdfButton##4"
                type="button"
                data-id={value.id}
                onClick={() => {
                  axiosPrivate
                    .post(`/cash-memo-download/${value.id}`)
                    .then((response) => {
                      // console.log(response);
                      if (response.data.code === 200) {
                        const html = response.data.data.html;
                        html2pdf().from(html).save();
                      }
                    })
                    .catch((error) => {
                      if (error.response) {
                        // Logic for handling error response
                      }
                    });
                }}
                className="btn btn-icon"
                title="Download Invoice"
              >
                <GrDownload style={{ color: "blue" }} size={17} />
              </button>
            );
          }
          value["date"] = formattedDate;
          value["action"] = buttons.length > 0 ? buttons : "-";
          return value;
        });

        setCashMemoState(cashMemoData);
      })
      .catch((error) => {
        if (error.response) {
          showErrorToast(error?.response?.data?.message);
        }
      });
  };

  useEffect(() => {
    getCashMemo();
  }, []);

  const viewCashMemoHandler = (id) => {
    axiosPrivate
      .get(`cash-memo/${id}`)
      .then((response) => {
        console.log(response);
        if (response.data.code === 200) {
          setSelectedCashMemo(response?.data?.data);
          setVisible(true); // Open the dialog
          // showSuccessToast("Cash Memo Fetched Successfully!");
          navigate(`/admin/cash-memo/view/${id}`);

          // After fetching the data, navigate to the edit page
        }
      })
      .catch((error) => {
        if (error.response) {
          showErrorToast(error?.response?.data?.errors?.msg);
        }
      });
  };

  const editCashMemoHandler = (id) => {
    axiosPrivate
      .get(`cash-memo/${id}`)
      .then((response) => {
        console.log(response);
        if (response.data.code === 200) {
          setSelectedCashMemo(response?.data?.data);
          setVisible(true); // Open the dialog
          // showSuccessToast("Cash Memo Fetched Successfully!");
          navigate(`/admin/cash-memo/edit/${id}`);

          // After fetching the data, navigate to the edit page
        }
      })
      .catch((error) => {
        if (error.response) {
          showErrorToast(error?.response?.data?.errors?.msg);
        }
      });
  };

  const printPdfHandler = (id) => {
    // Implement logic for printing PDF
    axiosPrivate
      .post(`/push-invoice-in-tally/${id}`)
      .then((response) => {
        if (response.data.code === 200) {
          getCashMemo();
        }
      })
      .catch((error) => {
        if (error.response) {
          // Logic for handling error response
        }
      });
  };

  const updatePdfHandler = (id) => {
    // Implement logic for printing PDF
    axiosPrivate
      .put(`/update-invoice-in-tally/${id}`)
      .then((response) => {
        console.log(response);
        if (response.data.code === 200) {
          // Logic for handling successful response
          console.log("successfully generated");
          getCashMemo();
        }
      })
      .catch((error) => {
        if (error.response) {
          // Logic for handling error response
        }
      });
  };

  const viewPdfHandler = (id) => {
    console.log(`${process.env.REACT_APP_BACKEND_MAIN_URL}/invoice/${id}`);
    window.open(
      `${process.env.REACT_APP_BACKEND_MAIN_URL}/invoice/${id}`,
      "_blank"
    );
  };

  return (
    <>
      <div className="mx-sm-5 mx-0">
        <div className="d-flex flex-sm-row flex-column justify-content-between align-items-center mb-3">
          <ul className="nav nav-tabs page-header-tab">
            <li className="nav-item">
              <div className="card-header">
                <div className="breadcrumb-area">
                  {/* header heading */}
                  <h1
                    className="card-title text-dark"
                    style={{ fontWeight: "700", fontSize: "18px" }}
                  >
                    Cash Memo
                  </h1>
                  {/* header routing */}
                  <ol className="headerroutes breadcrumb m-0 bg-none ">
                    <li className="item color-gray">|</li>
                    <li className="item">
                      <Link
                        to="/admin"
                        className="item mx-2"
                        style={{ fontSize: "14px" }}
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li className="item">
                      <FaGreaterThan style={{ fontSize: "14px" }} />
                    </li>
                    <li className="item mx-2 mt-1" style={{ fontSize: "14px" }}>
                      Cash Memo list
                    </li>
                  </ol>
                </div>
              </div>
            </li>
          </ul>
          <div className="header-action">
            <Link to="/admin/cash-memo/add" className="text-decoration-none">
              <button
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <i className="fe fe-plus mr-2" />
                Add
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <div
              className="tab-pane fade show active"
              id="cash-memo-list"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    {cashMemoState !== null && (
                      <DataTable2
                        lists={cashMemoState}
                        headers={headers}
                        searchItems={searchItems}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Dialog component */}
      <Dialog
        header="Cash Memo Details"
        // headerStyle={{ paddingLeft: "6%"}}
        visible={visible}
        style={{ margin: "auto", width: "80%", height: "80%" }}
        onHide={() => setVisible(false)}
      >
        {setVisible && (
          <iframe
            src={pdfUrl}
            width="100%"
            height="100%"
            title="Iframe Example"
          ></iframe>
        )}
      </Dialog>
    </>
  );
};

export default CashMemoList;
